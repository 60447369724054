import React from "react";
import { useCountUp } from "react-countup";

const SectionSuport = () => {
  const countUpComponent = React.useRef(null);
  const countUpReusablePattern = React.useRef(null);
  const countUpTribes = React.useRef(null);

  useCountUp({
    ref: countUpComponent,
    end: 46,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpReusablePattern,
    end: 3,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpTribes,
    end: 15,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });

  return (
    <section className="landing-support">
      <div className="landing-container">
        <h1 className="landing-h2 text-center">
          Support Design, Engineering, and Product
        </h1>
        <ul className="landing-support__list">
          <li>
            <strong ref={countUpComponent} />
            <span>Components</span>
          </li>
          <li>
            <strong ref={countUpReusablePattern} />
            <span>Reusable Patterns</span>
          </li>
          <li>
            <strong ref={countUpTribes} />
            <span>Tribes on Legion</span>
          </li>
        </ul>
        <div className="landing-support__content">
          <p>
            Pada akhir kuartal 4 2020 lalu, hanya ada 12 Components di Legion
            Design System. Tahun 2022, kami meningkatkan kontribusi component 2x
            lipat menjadi 32 Components. Akhir tahun 2022 timbul permasalahan
            pada urutan, struktur, konversi ke development, dan pembaruan Figma
            membuat 32 Components yang telah dirilis terlihat usang. Sampai
            akhirnya pada Juni 2023, inisiasi membuat Legion Design System V.2
            dimulai.
          </p>

          <p>
            Juni 2023 pula kami merilis pattern baru di Legion: Pattern
            Dashboard. Pattern Dashboard ini menjawab kebutuhan produk di DBT
            yang sebagian besar berbentuk platform Dashboard. Mengikuti pada
            pattern baru, kami melakukan iterasi ulang pada pattern Login &
            Register di Agustus 2023.
          </p>

          <p>
            Proyek pembaruan Legion ke V.2 hampir selesai pada September 2023,
            dengan capaian melampaui target dengan total 46 Components. Kami
            yakin bahwa komponen yang dibuat sudah teruji sesuai, mudah
            diperbarui, dan digunakan setelah melalui beberapa percobaan dan
            pengujian. Seiring berjalannya waktu, tim Legion bersinergi dengan
            tim Development untuk menciptakan inovasi baru bernama Instant
            Handoff.
          </p>

          <p>
            Instant Handoff mempermudah pengguna untuk mengonversi hasil desain
            ke codes secara cepat melalui generator. Hingga akhir tahun ini,
            baru satu produk digital yang diuji coba menggunakan Instant Handoff
            ini. Semoga uji coba ini berhasil dan bisa diimplementasi ke semua
            produk digital DBT di 2024. 🚀
          </p>
        </div>

        <div className="landing-support__access">
          <div className="landing-tag">
            Akses langsung Library Foundation & Component Kit Legion
          </div>
          <ul className="landing-support__access__list">
            <li>
              <img
                loading="lazy"
                src="/annual-report-2023/access-storybook.svg"
                alt="access storybook"
                width="120"
                height="120px"
              />
              <p>
                Akses Library UI Legion Web Desktop
                <br />
                <a
                  href="https://master--6420e4161e2acdd49d1d3e4b.chromatic.com/?path=/story/example--example"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Storybook Legion
                </a>
              </p>
              <p>Update terakhir: Juni 2023</p>
            </li>
            <li>
              <img
                loading="lazy"
                src="/annual-report-2023/access-play-store.svg"
                alt="access play-sroe"
                width="120"
                height="120px"
              />
              <p>
                Akses Library App Legion Mobile Android
                <br />
                <a href="https://play.google.com/store/apps/details?id=com.telkom.legion">
                  Legion Prototype Android{" "}
                </a>
              </p>
              <p>Update terakhir: September 2023</p>
            </li>
            <li>
              <img
                loading="lazy"
                src="/annual-report-2023/access-app-store.svg"
                alt="access play store"
                width="120"
                height="120px"
              />
              <p>
                Akses Library App Legion Mobile iOS
                <br />
                <a
                  href="https://apps.apple.com/id/app/legion/id6447614130"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Legion Prototype iOS
                </a>
              </p>
              <p>Update terakhir: September 2023</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SectionSuport;
