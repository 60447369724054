import React, { useState } from "react";

const gamificationStages = [
  {
    id: "level-1",
    name: "Level 1",
    value: "Starter",
    requirements: [
      "Implementasi Foundation Design, atau",
      "Implementasi Component Design",
    ],
    illustration: "gamification-1",
  },
  {
    id: "level-2",
    name: "Level 2",
    value: "Collaborator",
    requirements: [
      "Implementasi Foundation & Component Design",
      "Sosialisasi Legion ke Team & Stakeholder Product",
    ],
    illustration: "gamification-2",
  },
  {
    id: "level-3",
    name: "Level 3",
    value: "Supporter",
    requirements: [
      "Implementasi Foundation / Component Code",
      "Feedback Hasil Sosialisasi Legion",
      "Repository Produk di Adoption Tracker Dashboard Legion",
    ],
    illustration: "gamification-3",
  },
  {
    id: "level-4",
    name: "Level 4",
    value: "Automator",
    requirements: [
      "Implementasi Foundation & Component Code",
      "3x Kontribusi Request / Update Component",
      "2x Kontribusi Improvement Content Legion",
      "Lebih dari 40% Adoption Coverage",
    ],
    illustration: "gamification-4",
  },
  {
    id: "level-5",
    name: "Level 5",
    value: "Superior",
    requirements: [
      "Implementasi Foundation & Component Code",
      "6x Kontribusi Request / Update Component",
      "3x Kontribusi Improvement Content",
      "Lebih dari 80% Adoption Coverage",
      "1x Masuk Top 5 Leaderboard Tracker Legion",
    ],
    illustration: "gamification-5",
  },
];

const SectionGamification = () => {
  const [selectedLevel, setSelectedLevel] = useState("level-1");

  return (
    <section className="landing-gamification">
      <div className="landing-container">
        <h2 className="landing-h2 text-center">
          More Measurable and Competitive with Adoption Leveling
        </h2>
        <p className="text-center">
          Adoption Levelling Gamification System merupakan salah satu inisiatif
          kami dalam menghadapi tantangan meningkatkan jumlah implementasi dan
          penggunaan Legion. Sistem ini membuat proses adopsi jadi lebih
          terukur, menarik, dan kompetitif. Level adopsi dibagi menjadi 5 level
          yang diukur dengan syarat dan kriteria tertentu.
        </p>
        <div className="landing-gamification__content">
          {gamificationStages.map((stage, stageIdx) => (
            <div
              key={stage.id}
              className={`landing-gamification__content__item ${
                selectedLevel === stage.id
                  ? "landing-gamification__content__item__selected"
                  : ""
              }`}
              onClick={() => setSelectedLevel(stage.id)}
            >
              <img
                src={`/annual-report-2023/adoption-${stage.id}.png`}
                alt={`logo-adoption-${stage.id}`}
                loading="lazy"
              />
              {selectedLevel === stage.id && (
                <svg height="85" width="85">
                  <circle cx="40" cy="40" r="40" fill="white" />
                  Sorry, your browser does not support inline SVG.
                </svg>
              )}
              <span>{stage.name}</span>
              <p>{stage.value}</p>
            </div>
          ))}
        </div>
        {gamificationStages.map(
          (stage, stageIdx) =>
            selectedLevel === stage.id && (
              <div className="landing-gamification__content__details">
                <div className="landing-gamification__content__details__left">
                  <h2>
                    {stage.name} - {stage.value}
                  </h2>
                  <ul>
                    Syarat Implementasi:
                    {stage.requirements.map((req) => (
                      <li>{req}</li>
                    ))}
                  </ul>
                </div>
                <img
                  src={`/annual-report-2023/banner-adoption-${stage.id}.png`}
                  alt="desain-banner"
                />
              </div>
            )
        )}
      </div>
    </section>
  );
};

export default SectionGamification;
