import React from 'react'
import Marquee from "react-fast-marquee";

const SectionMarque = () => {
  return (
    <section className="landing-marque">
      <div className="landing-marque__inner">
        <Marquee autoFill>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 0C14.2313 7.63411 20.3659 13.7687 28 14C20.2066 14 14.2241 20.3797 14 28C14 20.268 7.73199 14 0 14C7.62029 13.7759 14 7.79343 14 0Z" fill="white"/>
          </svg>
          <span>Adoption Levelling By Legion Design System</span>
        </Marquee>
      </div>
    </section>
  )
}

export default SectionMarque