import React from "react";
import { useCountUp } from "react-countup";

const SectionLeveling = () => {
  const countUpAdoptionLevelOne = React.useRef(null);
  const countUpAdoptionLevelTwo = React.useRef(null);
  const countUpAdoptionLevelThree = React.useRef(null);

  useCountUp({
    ref: countUpAdoptionLevelOne,
    end: 26,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpAdoptionLevelTwo,
    end: 46,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpAdoptionLevelThree,
    end: 33,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });

  return (
    <section className="landing-leveling">
      <div className="landing-container">
        <div className="landing-leveling__content">
          <h2 className="landing-h2">Adoption Levelling Product by Numbers</h2>
          <p>
            Sistem yang kompetitif menjadikan proses Adoption Levelling berhasil
            dalam meningkatkan jumlah implementasi Legion Design System.
            Terlihat dari peningkatan sangat signifikan yang terjadi di Level 2
            (Collaborator) dan Level 3 (Supporter) hanya dalam kurun waktu 5
            bulan, walaupun sampai saat ini belum ada produk digital (DTP) yang
            mencapai Level 4 (Automator) dan Level 5 (Superior).
          </p>
          <ul className="landing-leveling__list">
            <li>
              <span className="landing-leveling__list__card">
                <strong ref={countUpAdoptionLevelOne} />
                <span>
                  Products are
                  <br />
                  <b>Starter</b>
                </span>
              </span>
              <span className="landing-leveling__list__category">
                <img
                  src="/annual-report-2023/adoption-level-1.png"
                  alt="level 1"
                  width="50"
                  height="auto"
                />{" "}
                Level 1
              </span>
              <span>
                Pada kuartal 4 2023, produk digital di Level 1 naik ke Level 2.
                Sehingga terjadi penurunan 50% atau sejumlah 44 produk digital.
              </span>
            </li>
            <li>
              <span className="landing-leveling__list__card">
                <strong ref={countUpAdoptionLevelTwo} />
                <span>
                  Products going to
                  <br />
                  <b>Collaborator</b>
                </span>
              </span>
              <span className="landing-leveling__list__category">
                <img
                  src="/annual-report-2023/adoption-level-2.png"
                  alt="level 2"
                  width="50"
                  height="auto"
                />{" "}
                Level 2
              </span>
              <span>
                Penyebab penurunan produk digital di Level 1 adalah hal positif.
                Karena pada akhir tahun 2023, terhitung sebanyak 49 produk
                digital naik ke Level 2.
              </span>
            </li>
            <li>
              <span className="landing-leveling__list__card">
                <strong ref={countUpAdoptionLevelThree} />
                <span>
                  Products destined to be
                  <br />
                  <b>Supporter!</b>
                </span>
              </span>
              <span className="landing-leveling__list__category">
                <img
                  src="/annual-report-2023/adoption-level-3.png"
                  alt="level 3"
                  width="50"
                  height="auto"
                />{" "}
                Level 3
              </span>
              <span>
                Pada periode Agustus - September 2023 tercatat ada 30 produk
                digital di Level 3.Saat ini jumlah produk digital di Level 3
                mengalami kenaikan menjadi 33 produk.
              </span>
            </li>
          </ul>
        </div>
        <div className="landing-leveling__cta">
          <p>
            Hasil level metode nilai Median dari implementasi Legion Design
            System sepanjang tahun 2023 berada pada level:
          </p>
          <div className="landing-leveling__cta__button">
            <img
              loading="lazy"
              src="/annual-report-2023/adoption-level-2.png"
              alt="level 1"
            />
            Level 2 - Collaborator 🎉
          </div>
          <p>
            *Data Score Level pada Tribe/Ecosystem didapat berdasarkan nilai
            tengah/median pada setiap DTP yang ada di Tribe/Ecosystem.
          </p>
        </div>
      </div>
      <div className="landing-leveling__decoration"></div>
    </section>
  );
};

export default SectionLeveling;
