import React from "react";
import { navigate } from "gatsby";

const SectionHero = ({ scrollDown }) => {
  const navigateUrl = () => navigate("/");
  const [navbar, setNavbar] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", changeBackground);

    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const changeBackground = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      let collapsedHeight = 106;

      if (windowHeight >= collapsedHeight) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    }
  };

  return (
    <section className="landing-hero">
      <nav className={navbar ? "landing-nav active" : "landing-nav"}>
        {navbar ? (
          <a onClick={navigateUrl}>
            <img
              loading="lazy"
              src="/annual-report-2023/header-logo-full-color.svg"
              alt="Legion Logo"
              width={160}
              height={41.8787}
            />
          </a>
        ) : (
          <a onClick={navigateUrl}>
            <img
              loading="lazy"
              src="/annual-report-2023/header-logo-white.svg"
              alt="Legion Logo"
              width={160}
              height={41.8787}
            />
          </a>
        )}
      </nav>
      <div className="landing-container-fluid">
        <div className="landing-hero__inner">
          <div className="landing-hero__headline">
            <h1>Legion 2023</h1>
            <p>Yearly Highlights 🎉</p>
            <a href="#story" onClick={scrollDown}>
              Show me the story
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_1167_5917)">
                  <path
                    d="M12 5V19"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18 13L12 19"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 13L12 19"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1167_5917">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div className="landing-hero__card">
            <img
              className="landing-hero__card__top-right"
              src="/annual-report-2023/hero-top-right.png"
              alt="hero-top-right"
              height="200"
              width="200"
            />
            <img
              className="landing-hero__card__top-right-shadow"
              src="/annual-report-2023/hero-top-right-shadow.png"
              alt="hero-top-right-shadow"
              height="41"
              width="130"
            />
            <img
              className="landing-hero__card__bottom-left"
              src="/annual-report-2023/hero-bottom-left.png"
              alt="hero-bottom-left"
              height="144"
              width="144"
            />
            <img
              className="landing-hero__card__bottom-left-shadow"
              src="/annual-report-2023/hero-bottom-left-shadow.png"
              alt="hero-bottom-left-shadow"
              height="41"
              width="130"
            />
            <h2>
              Merancang dengan efektif berkembang dengan <mark>konsisten</mark>
            </h2>
            <time>2020 - 2023.</time>
            <p>
              Sejak 3 tahun lalu,{" "}
              <strong>
                Legion Design System konsisten berkembang sebagai design system
                di Telkom Indonesia
              </strong>
              . Kami efektif merancang, melakukan perbaikan, dan merealisasikan
              inisiatif untuk menghadapi berbagai tantangan dan dinamika. Apa
              saja yang terjadi pada Legion Design System selama tahun 2023?
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
