import React from 'react'

const SectionInfluence = () => {
  return (
    <section className="landing-influence">
      <div className="landing-container">
        <div className="landing-influence__inner">
          <h2 className="landing-h2">How did Legion influence design and development processes?</h2>
          <ul>
            <li>
              <span>1.</span>
              <span>
                Percepatan Proses Crafting
                <span>Proses desain dan crafting pada tahap hi-fidelity meningkat jadi <strong>50% lebih cepat.</strong></span>
              </span>
              <img src="/annual-report-2023/influence-crafting.svg" loading="lazy" alt="percepatan proses crafting" width="277" height="181" />
            </li>
            <li>
              <span>2.</span>
              <span>
                Handover Design to Code Lebih Kilat
                <span>Peningkatan waktu pada proses desain ke development <strong>meningkat jadi 40% lebih cepat.</strong></span>
              </span>
              <img src="/annual-report-2023/influence-handover.svg" loading="lazy" alt="hondover design to code lebih kuat" width="257" height="216" />
            </li>
            <li>
              <span>3.</span>
              <span>
                Efisiensi Penggunaan Aset
                <span>Penggunaan aset desain dan code menjadi <strong>10x lebih reusable</strong> untuk semua project yang sedang berjalan.</span>
              </span>
              <img src="/annual-report-2023/influence-efesiensi.svg" loading="lazy" style={{ right: "24px" }} alt="efesiensi penggunaan aset" width="178" height="185" />
            </li>
            <li>
              <span>4.</span>
              <span>
                Optimalisasi Proses Custom Component
                <span>Proses slicing atau custom component code meningkat jadi <strong>2x lebih cepat</strong> diimplementasi.</span>
              </span>
              <img src="/annual-report-2023/influence-optimalisasi.svg" style={{ right: "24px", bottom: '12px' }} loading="lazy" alt="optimalisasi proses custom component" width="235" height="155" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default SectionInfluence