import React from "react";
import SectionHero from "./section-hero";
import SectionPrinciple from "./section-principle";
import SetionReport from "./section-report";
import SectionSuport from "./section-support";
import SectionMarque from "./section-marque";
import SectionTeam from "./section-team";
import SectionInfluence from "./section-influence";
import SectionGamification from "./section-gamification";
import SectionLeveling from "./section-leveling";
import SectionTestimony from "./section-testimony";
import SectionImplement from "./section-implement";
import SectionFooter from "./section-footer";
import "./style.css";

const AnnualReport2023 = () => {
  const principleSection = React.useRef(null);

  const scrollDown = () => {
    window.scrollTo({
      behavior: "smooth",
      top: principleSection.current.offsetTop - 100,
    });
  };

  return (
    <div className="landing-2023">
      <SectionHero scrollDown={scrollDown} />
      <SectionPrinciple ref={principleSection} />
      <SetionReport />
      <SectionSuport />
      <SectionMarque />
      <SectionGamification />
      <SectionLeveling />
      <SectionInfluence />
      <SectionTeam />
      <SectionTestimony />
      <SectionImplement />
      <SectionFooter />
    </div>
  );
};

export default AnnualReport2023;
