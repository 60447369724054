import React from "react";

const SectionFooter = () => {
  return (
    <section className="landing-footer">
      <div className="landing-container">
        <div className="landing-footer__decoration" />
        <div className="landing--footer__content">
          <h2 className="landing--footer__content__title text-center">
            Legion: Co-Creating the Future of Product Development at Telkom
            Indonesia
          </h2>
          <p className="text-center">
            Menyederhanakan dan mempermudah proses pengembangan produk digital
            adalah visi kami sejak Legion dibangun. Visi tersebut menjadi fokus
            kami di tahun depan, yaitu menciptakan dampak nyata untuk
            pengembangan berbagai produk digital Telkom Indonesia dengan lebih
            cepat dan mudah. Dalam perjalanannya, Legion menjadikan budaya
            kolaborasi, efisiensi, kreativitas, dan menjaga kualitas sebagai
            standar pengguna untuk membangun produk digital.
            <br />
            <br />
            Di tahun 2024 kami mempunyai tujuan untuk memberdayakan kreativitas
            pengguna dan meningkatkan kontribusi komponen. Tujuan itu sejalan
            dengan harapan kami untuk meningkatkan sense of belonging pengguna
            terhadap produk yang mereka bangun. Kontribusi semua pengguna adalah
            hal yang penting bagi kami untuk selalu membuat aset yang mudah
            diakses. Tentunya kami akan terus berusaha berinovasi menjawab
            tantangan dalam membangun jembatan antara Legion dan pengguna.
            <br />
            <br />
            Mari bergabung bersama kami membangun kolaborasi untuk memberikan
            dampak yang nyata. Bersama kita merancang lingkungan di mana
            kesederhanaan, kolaborasi, dan adopsi tidak hanya tujuan, tetapi
            dasar dari semua yang kami ciptakan.
          </p>
        </div>
        <div className="landing--footer__action">
          <a href="https://www.surveymonkey.com/r/fbannuallegion" role="button">
            Tell us. Improve together
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionFooter;
