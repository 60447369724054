import React from "react";

const brands = [
  {
    id: "agree",
    path: "implement-agree.svg",
  },
  {
    id: "bigbox",
    path: "implement-bigbox.svg",
  },
  {
    id: "logee",
    path: "implement-logee.svg",
  },
  {
    id: "myindihome",
    path: "implement-myindihome.svg",
  },
  {
    id: "mysooltan",
    path: "implement-mysooltan.svg",
  },
  {
    id: "mytens",
    path: "implement-mytens.svg",
  },
  {
    id: "oss",
    path: "implement-oss.svg",
  },
  {
    id: "padiumkm",
    path: "implement-padiumkm.svg",
  },
  {
    id: "pijar",
    path: "implement-pijar.svg",
  },
];

const SectionImplement = () => {
  return (
    <section className="landing-implement">
      <div className="landing-container">
        <h2 className="landing-h2__implement text-center">
          Products that Implement the Legion Design System
        </h2>
        <div className="landing-implement__brand">
          {brands.map((brand, brandIdx) => (
            <img
              key={brandIdx}
              loading="lazy"
              alt={`brand-${brand.id}-logo`}
              src={`/annual-report-2023/${brand.path}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionImplement;
